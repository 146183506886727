import { Sidebar } from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

function CompleteHotel() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 flex flex-col p-8">
        <button className="text-black px-4 py-2 rounded flex items-center mb-4 self-start" onClick={() => navigate(-1)}>
          <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
        </button>

        <div className="flex-1 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
            <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">Compléter mon Hôtel</h2>

            <div className="space-y-6">
              <button onClick={() => navigate(`/hotels/${id}/meshs`)} className="w-full bg-[#98B77F] text-white font-semibold py-3 rounded-lg hover:bg-[#365644] transition duration-300">
                Gestion des meshs
              </button>

              <button onClick={() => navigate(`/hotels/${id}/editrooms`)} className="w-full bg-[#98B77F] text-white font-semibold py-3 rounded-lg hover:bg-[#365644] transition duration-300">
                Gestion des chambres
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteHotel;
