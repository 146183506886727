import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Floor } from "../components/Floor/Floor";
// import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { AiOutlineEdit } from "react-icons/ai";
import { Carrousel } from "../components/Hotel/Carroussel_Hotel";

function HotelPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [listeEtage, setListeEtage] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    stars_number: 0,
    heating_type: "",
    subscription_type: 0,
    content: "",
    is_visible: 1,
  });

  // const auth = useAuth();
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    axios
      .get(`${API_URL}/hotels/${id}/floors`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setListeEtage(response.data.result);
      });

    axios
      .get(`${API_URL}/hotels/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setHotel(response.data.result);
        setFormData({
          name: response.data.result.name || "",
          location: response.data.result.location || "",
          stars_number: response.data.result.stars_number || 0,
          heating_type: response.data.result.heating_type || "",
          subscription_type: response.data.result.subscription_type || 0,
          content: response.data.result.content || "",
          is_visible: response.data.result.is_visible ?? true,
        });
      });
  }, [id, API_URL, token]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "radio" ? parseInt(value) : value,
    });
  };

  const handleSubmit = () => {
    axios
      .patch(`${API_URL}/hotels/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Ok");
        navigate(0);
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error("Error updating hotel:", err);
      });
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center justify-between">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate("/")}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>
          <h1 className="text-2xl font-bold">{hotel?.name}</h1>
          <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3" onClick={toggleModal} style={{ marginLeft: "auto" }}>
            {/* <AiOutlineEdit /> */}
            Modifier les informations cet hôtel
          </button>
        </div>

        <div className="mt-5 flex flex-col">
          <p className="text-lg font-semibold text-black mb-2">Informations concernant l'hôtel</p>
          <span className="bg-gray-200 rounded-lg text-black italic p-4 text-m mb-3">{hotel?.content}</span>
        </div>

        {hotel && <Carrousel hotel={hotel} className="mt-4" />}

        <hr className="my-6 border-t-2 border-gray-300 w-full" />

        <div className="mb-4 mt-4">
          <div className="flex justify-between mb-2">
            <h2 className="font-bold text-lg mb-2">Liste des Étages</h2>
            <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3" onClick={() => navigate(`/hotels/${id}/complete`)}>
              Compléter cet hôtel
            </button>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Étage</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Nombre de Chambres</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-center">
                {listeEtage.length === 0 ? (
                  <div>Aucun étabe disponible pour le moment.</div>
                ) : (
                  listeEtage.map((etage, index) => {
                    return <Floor key={index} data={etage} state={hotel} />;
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
              <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Modification de l'hôtel</h2>
              <button
                type="button"
                className=" absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl"
                onClick={toggleModal}
                aria-label="Close"
              >
                &times;
              </button>
              {/* Première section en deux colonnes */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Nom de l'hôtel</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black"
                    placeholder={formData.name}
                  />
                </div>

                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Adresse de l'hôtel</label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black"
                    placeholder={formData.location}
                  />
                </div>

                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Nombre d'étoiles</label>
                  <input
                    type="number"
                    name="stars_number"
                    value={formData.stars_number}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black"
                    placeholder={formData.stars_number}
                  />
                </div>

                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Type de chauffage</label>
                  <input
                    type="text"
                    name="heating_type"
                    value={formData.heating_type}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black"
                    placeholder={formData.heating_type}
                  />
                </div>

                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Type d'abonnement</label>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="subscription_type"
                      value="0"
                      checked={formData.subscription_type === 0}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label className="mr-4">Standard</label>
                    <input
                      type="radio"
                      name="subscription_type"
                      value="1"
                      checked={formData.subscription_type === 1}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label>Premium</label>
                  </div>
                </div>

                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium">Visible</label>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="is_visible"
                      value="1"
                      checked={formData.is_visible === 1}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label className="mr-4">Oui</label>
                    <input
                      type="radio"
                      name="is_visible"
                      value="0"
                      checked={formData.is_visible === 0}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <label>Non</label>
                  </div>
                </div>
              </div>

              {/* Deuxième section pour les informations supplémentaires */}
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Informations supplémentaires</label>
                <input
                  type="text"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black"
                  placeholder={formData.content}
                />
              </div>

              <button
                onClick={handleSubmit}
                className="w-full bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200"
              >
                Enregistrer les modifications
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HotelPage;
