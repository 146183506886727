import { Sidebar } from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";

function GestionRoomsForHotel() {
  const navigate = useNavigate();
  const { id: hotel_id } = useParams();

  // const auth = useAuth();
  const token = null;
  const API_URL = "https://api-manager-staging.luniwave.com";

  const [allRoomsNotComplete, setAllRoomsNotComplete] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allMeshs, setAllMeshs] = useState([]);
  const [completeMode, setCompleteMode] = useState(false);
  const [detailsVisibility, setDetailsVisibility] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios
      .get(`${API_URL}/hotels/${hotel_id}/rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAllRooms(response.data.result);
        const filteredRooms = response.data.result.filter((room) => !room.unicast || !room.mesh_id || !room.room_nb);
        setAllRoomsNotComplete(filteredRooms);
      })
      .catch((error) => {
        console.error("Error fetching rooms:", error);
      });

    axios
      .get(`${API_URL}/hotels/${hotel_id}/mesh`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAllMeshs(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching meshs for hotel:", error);
      });
  }, [hotel_id, token, API_URL]);

  const handleInputChange = (index, field, value, roomListSetter) => {
    roomListSetter((prevRooms) => {
      const updatedRooms = [...prevRooms];
      updatedRooms[index] = {
        ...updatedRooms[index],
        [field]: value,
      };
      return updatedRooms;
    });
  };

  const toggleDetailsVisibility = (roomId) => {
    setDetailsVisibility((prevState) => ({
      ...prevState,
      [roomId]: !prevState[roomId],
    }));
  };

  const getValidRooms = () => {
    const roomsToCheck = completeMode ? allRooms : allRoomsNotComplete;
    return roomsToCheck.filter((room) => room.unicast && room.mesh_id);
  };

  const handleSubmit = () => {
    const validRooms = getValidRooms();

    if (validRooms.length === 0) {
      setErrorMessage("Aucune chambre valide n'a été trouvée.");
      return;
    }

    setErrorMessage("");

    validRooms.map((room) => {
      axios
        .put(
          `${API_URL}/hotels/${hotel_id}/floors/${room.floor_id}/rooms/${room.room_id}`,
          { is_lunishower: room.is_lunishower, is_pmr: room.is_pmr, nb_places: room.nb_places, room_nb: room.room_nb },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const moduleRequest = completeMode
            ? axios.patch(
                `${API_URL}/modules/${room.module_id}`,
                { unicast: room.unicast, is_rainshower: room.is_rainshower, version: room.version, obj_base: 60, mode: "0", mesh_id: room.mesh_id },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
            : axios.post(
                `${API_URL}/associateModuleRoom`,
                { unicast: room.unicast, is_rainshower: room.is_rainshower, version: room.version, obj_base: 60, mode: "0", mesh_id: room.mesh_id, room_id: room.room_id },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

          return moduleRequest;
        })
        .then((response) => {
          console.log("response : ", response);
        })
        .catch((error) => {
          console.error("Error submitting room changes:", error);
        });
    });
  };

  const roomsToDisplay = completeMode ? allRooms : allRoomsNotComplete;

  return (
<div className="flex h-screen bg-gray-100">
  <Sidebar />
  <div className="flex-1 p-8 overflow-auto mb-4">
    <div className="flex items-center">
      <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(-1)}>
        <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
      </button>
      <h1 className="text-2xl font-bold">Modifier les Chambres</h1>
    </div>

    <hr className="my-6 border-t-2 border-gray-300 w-full" />

    {errorMessage && <div className="bg-red-600 text-white p-2 rounded-md mb-4">{errorMessage}</div>}

    <div className="flex gap-4 mb-6">
      <button onClick={() => setCompleteMode(false)} className="flex-1 bg-[#365644] text-white font-semibold py-2 rounded-lg hover:bg-gray-500 transition duration-300">
        Afficher seulement les chambres à compléter
      </button>
      <button onClick={() => setCompleteMode(true)} className="flex-1 bg-[#365644] text-white font-semibold py-2 rounded-lg hover:bg-gray-500 transition duration-300">
        Afficher toutes les chambres
      </button>
    </div>

    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {roomsToDisplay && roomsToDisplay.map((room, index) => (
        <div key={room.room_id} className="bg-white border border-gray-200 rounded-md shadow-sm p-3 hover:shadow-md transition-shadow duration-200">
          <div className="flex flex-col gap-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">Room ID</label>
              <div className="text-gray-900 text-sm">{room.room_id}</div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Unicast</label>
              <input type="text" value={room.unicast || ""} onChange={(e) => handleInputChange(index, "unicast", e.target.value, completeMode ? setAllRooms : setAllRoomsNotComplete)} className="border border-gray-300 p-1 rounded-md w-full text-sm focus:outline-none focus:ring-1 focus:ring-green-500" />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Mesh ID</label>
              <select value={room.mesh_id || ""} onChange={(e) => handleInputChange(index, "mesh_id", e.target.value, completeMode ? setAllRooms : setAllRoomsNotComplete)} className="border border-gray-300 p-1 rounded-md w-full text-sm focus:outline-none focus:ring-1 focus:ring-green-500">
                <option value="">Sélectionner un Mesh</option>
                {allMeshs.map((mesh) => (
                  <option key={mesh.id} value={mesh.id}>
                    {mesh.id} - {mesh.ip_adress_hub}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Room Number</label>
              <input type="text" value={room.room_nb || ""} onChange={(e) => handleInputChange(index, "room_nb", e.target.value, completeMode ? setAllRooms : setAllRoomsNotComplete)} className="border border-gray-300 p-1 rounded-md w-full text-sm focus:outline-none focus:ring-1 focus:ring-green-500" />
            </div>

            <div className="text-right">
              <button onClick={() => toggleDetailsVisibility(room.room_id)} className="bg-gray-500 text-white text-sm py-1 px-2 rounded-md hover:bg-gray-600">
                {detailsVisibility[room.room_id] ? "Masquer" : "Plus de détails"}
              </button>
            </div>

            {detailsVisibility[room.room_id] && (
              <div className="bg-gray-100 p-2 rounded-md mt-2 border border-gray-200">
                <div className="flex gap-4 mb-2">
                  <div className="flex items-center gap-2">
                    <label className="block text-sm font-medium text-gray-700">PMR</label>
                    <input
                      type="checkbox"
                      checked={room.is_pmr || false}
                      onChange={(e) => handleInputChange(index, "is_pmr", e.target.checked, completeMode ? setAllRooms : setAllRoomsNotComplete)}
                      className="h-4 w-4"
                    />
                  </div>

                  <div className="flex items-center gap-2">
                    <label className="block text-sm font-medium text-gray-700">Rainshower</label>
                    <input
                      type="checkbox"
                      checked={room.is_rainshower || false}
                      onChange={(e) => handleInputChange(index, "is_rainshower", e.target.checked, completeMode ? setAllRooms : setAllRoomsNotComplete)}
                      className="h-4 w-4"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Nombre de places</label>
                  <input
                    type="number"
                    value={room.nb_places || ""}
                    onChange={(e) => handleInputChange(index, "nb_places", e.target.value, completeMode ? setAllRooms : setAllRoomsNotComplete)}
                    className="border border-gray-300 p-1 rounded-md w-full text-sm focus:outline-none focus:ring-1 focus:ring-green-500"
                  />
                </div>
              </div>
            )}

          </div>
        </div>
      ))}
    </div>

    <div className="fixed bottom-0 left-0 right-0 p-4 shadow-md">
      <div className="text-right">
        <button onClick={handleSubmit} className="bg-[#365644] text-white py-2 px-4 rounded-md">
          Sauvegarder les changements
        </button>
      </div>
    </div>

  </div>
</div>


  );
}

export default GestionRoomsForHotel;
