import React from "react";
import { useNavigate } from "react-router-dom";

export function Floor({ data }) {
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => {
        navigate(`/hotels/${data.hotel_id}/floors/${data.id}`);
      }}
      className="cursor-pointer hover:bg-gray-50"
    >
      <td className="px-6 py-4 whitespace-nowrap">{data.floor_number}</td>
      <td className="px-6 py-4 whitespace-nowrap">{data.num_rooms}</td>
    </tr>
  );
}
