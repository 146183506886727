import React, { useEffect, useState } from "react";
import axios from "axios";
import { Sidebar } from "../../components/Sidebar";
import refreshIcon from "../../assets/refresh.png";
// import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

function Alertes() {
  const navigate = useNavigate();
  const [roomsAlerts, setRoomsAlerts] = useState([]);
  const [alertSettings, setAlertSettings] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [newAlertSetting, setNewAlertSetting] = useState({
    minimum_battery: "",
    no_heartbeat_period: "",
    max_day_without_interaction: "",
    avg_min_consumption: "",
    avg_max_consumption: "",
    max_days_no_shower: "",
  });
  // const auth = useAuth();
  const token = null; // Placeholder for token
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(`${API_URL}/alertsRooms`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoomsAlerts(response.data.result);
      } catch (error) {
        console.log("Erreur lors de la récupération des alertes.", error);
      }
    };
    fetchAlerts();
  }, []);

  const fetchAlertSettings = async () => {
    try {
      const response = await axios.get(`${API_URL}/alertsSettings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAlertSettings(response.data.result);
    } catch (error) {
      console.log("Erreur lors de la récupération des paramètres d'alerte.", error);
    }
  };

  const generateAlerts = async () => {
    try {
      await axios.post(`${API_URL}/generate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (error) {
      console.log("Erreur lors de la génération des alertes.", error);
    }
  };

  const handleNewAlertSettingChange = (e) => {
    setNewAlertSetting({
      ...newAlertSetting,
      [e.target.name]: e.target.value,
    });
  };

  const handleNewAlertSettingSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/alertsSettings`, newAlertSetting, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchAlertSettings(); // Refresh the settings after adding a new one
      setNewAlertSetting({
        minimum_battery: "",
        no_heartbeat_period: "",
        max_day_without_interaction: "",
        avg_min_consumption: "",
        avg_max_consumption: "",
        max_days_no_shower: "",
      });
    } catch (error) {
      console.log("Erreur lors de la création du nouveau paramètre d'alerte.", error);
    }
  };

  const getCircle = (value) => {
    return value === 1 ? <span className="inline-block w-4 h-4 bg-red-500 rounded-full"></span> : <span className="inline-block w-4 h-4 bg-green-500 rounded-full"></span>;
  };

  const toggleSettingsModal = () => {
    setShowSettings(!showSettings);
    if (!showSettings) fetchAlertSettings(); // Fetch settings only when opening
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        <div className="flex justify-between items-center mb-4 mt-8">
          <h1 className="mb-4 text-2xl ml-6 font-semibold">Alertes des chambres</h1>
          <div className="flex space-x-4 mr-4">
            <button className="focus:outline-none m-2" onClick={generateAlerts}>
              <img src={refreshIcon} alt="Refresh" className="w-7 h-7 " />
            </button>
            <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3 ml-4" onClick={toggleSettingsModal}>
              Ajouter un nouveau paramètre
            </button>
          </div>
        </div>
        <hr className="my-6 border-t-2 border-gray-300 w-full" />

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          {roomsAlerts && roomsAlerts.length === 0 ? (
            <div className="p-6 text-center text-gray-500">Aucune alerte disponible pour le moment.</div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">NUMERO CHAMBRE</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">HOTEL</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">BATTERY</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">HEARTBEAT</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">INTERACTIONS</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">DOUCHES</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">MOYENNE BASSE</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">MOYENNE HAUTE</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {roomsAlerts.map((room) => (
                  <tr key={room.room_id} className="text-center cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/hotels/${room.hotel_id}/rooms/${room.room_id}/alerts`)}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{room.room_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{room.hotel_name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getCircle(room.alert_type_1)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getCircle(room.alert_type_2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getCircle(room.alert_type_3)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getCircle(room.alert_type_4)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getCircle(room.alert_type_5)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getCircle(room.alert_type_6)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {showSettings && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full space-y-4">
              <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Paramètres des Alertes</h2>
              
              <button 
                type="button" 
                className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" 
                onClick={toggleSettingsModal} 
                aria-label="Close"
              >
                &times;
              </button>
              
              <form onSubmit={handleNewAlertSettingSubmit} className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Minimum Battery (%)</label>
                    <input 
                      type="number" 
                      name="minimum_battery" 
                      value={newAlertSetting.minimum_battery} 
                      onChange={handleNewAlertSettingChange} 
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
                      required 
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">No Heartbeat Period (days)</label>
                    <input 
                      type="number" 
                      name="no_heartbeat_period" 
                      value={newAlertSetting.no_heartbeat_period} 
                      onChange={handleNewAlertSettingChange} 
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
                      required 
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Max Days Without Interaction</label>
                    <input 
                      type="number" 
                      name="max_day_without_interaction" 
                      value={newAlertSetting.max_day_without_interaction} 
                      onChange={handleNewAlertSettingChange} 
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
                      required 
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Average Minimum Consumption (L)</label>
                    <input 
                      type="number" 
                      name="avg_min_consumption" 
                      value={newAlertSetting.avg_min_consumption} 
                      onChange={handleNewAlertSettingChange} 
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
                      required 
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Average Maximum Consumption (L)</label>
                    <input 
                      type="number" 
                      name="avg_max_consumption" 
                      value={newAlertSetting.avg_max_consumption} 
                      onChange={handleNewAlertSettingChange} 
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
                      required 
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium text-gray-700">Max Days No Shower</label>
                    <input 
                      type="number" 
                      name="max_days_no_shower" 
                      value={newAlertSetting.max_days_no_shower} 
                      onChange={handleNewAlertSettingChange} 
                      className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
                      required 
                    />
                  </div>
                </div>

                <div className="flex justify-center">
                  <button type="submit" className="py-2 px-4 bg-[#365644] text-white rounded-lg shadow hover:bg-[#2d4737] transition mb-4 mt-2 item-center">
                    Ajouter le nouveau paramètre
                  </button>
                </div>
              </form>

              <hr className="my-6 border-t-2 border-gray-300 w-full" />

              <h3 className="text-lg font-semibold text-gray-700">Paramètres existants :</h3>
              <div className="flex overflow-x-auto space-x-2 py-2">
                {alertSettings.map((setting) => (
                  <div 
                    key={setting.id} 
                    className="bg-gray-100 p-3 rounded-lg shadow-md w-50 mr-2 flex-shrink-0"
                  >
                    <p className="font-semibold text-md pb-2">Profil #{setting.id}</p>
                    <p className="text-sm">Batterie Min: {setting.minimum_battery}%</p>
                    <p className="text-sm">Heartbeat (Jours): {setting.no_heartbeat_period}</p>
                    <p className="text-sm">Sans Interaction (Jours): {setting.max_day_without_interaction}</p>
                    <p className="text-sm">Conso Min (L): {setting.avg_min_consumption}</p>
                    <p className="text-sm">Conso Max (L): {setting.avg_max_consumption}</p>
                    <p className="text-sm">Sans Douche (Jours): {setting.max_days_no_shower}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Alertes;
