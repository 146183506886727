import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
// import { useAuth } from "react-oidc-context";

function AddFloor() {
  const location = useLocation();
  const navigate = useNavigate();
  const { newHotel, num_rooms, num_floor } = location.state;

  // const auth = useAuth();
  const token = null;
  const API_URL = "https://api-manager-staging.luniwave.com";

  const [roomType, setRoomType] = useState({ isRainshower: false, isPMR: false, nb_beds: "" });
  const [moduleType, setModuleType] = useState({ version: 0, mode: 0, obj_base: "" });
  const [roomsPerFloor, setRoomsPerFloor] = useState(Array.from({ length: num_floor }, () => ""));

  const handleRoomTypeChange = (field, value) => setRoomType({ ...roomType, [field]: value });
  const handleModuleTypeChange = (field, value) => setModuleType({ ...moduleType, [field]: value });
  const handleRoomsPerFloorChange = (index, value) => {
    const newRoomsPerFloor = [...roomsPerFloor];
    newRoomsPerFloor[index] = value;
    setRoomsPerFloor(newRoomsPerFloor);
  };

  const handleSubmit = async () => {
    const totalRooms = roomsPerFloor.reduce((acc, rooms) => acc + Number(rooms), 0);

    if (totalRooms !== Number(num_rooms)) {
      alert(`Le nombre de chambres indiqué n'est pas correct. Vous avez mis ${totalRooms} chambres alors que l'hôtel contient ${num_rooms} chambres.`);
      return;
    }

    try {
      const hotelResponse = await axios.post(`${API_URL}/hotels`, newHotel, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const createdHotelId = hotelResponse.data.result.insertId;

      for (let i = 0; i < roomsPerFloor.length; i++) {
        const nbfloor = i + 1;
        const nbrooms = roomsPerFloor[i];

        const floorResponse = await axios.post(`${API_URL}/hotels/${createdHotelId}/floors`, { floor_number: nbfloor, num_rooms: nbrooms }, { headers: { Authorization: `Bearer ${token}` } });

        const createdFloorId = floorResponse.data.result.insertId;

        for (let j = 0; j < nbrooms; j++) {
          const roomNumber = j + 1;

          await axios.post(
            `${API_URL}/hotels/${createdHotelId}/floors/${createdFloorId}/rooms`,
            {
              is_lunishower: true,
              is_pmr: roomType.isPMR,
              nb_places: roomType.nb_beds,
              room_nb: roomNumber,
              hotel_id: createdHotelId,
              floor_id: createdFloorId,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
        }
      }

      alert("Hôtel, étages et chambres créés avec succès !");
      navigate(`/hotels/${createdHotelId}/complete`);
    } catch (error) {
      console.error("Erreur lors de la création de l'hôtel, des étages ou des chambres :", error);
      alert("Erreur lors de la création de l'hôtel, des étages ou des chambres.");
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 p-8 overflow-hidden">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto h-full flex flex-col justify-between">
          <h2 className="text-xl font-bold text-center mb-8 text-gray-800">Ajouter les Étages et les Chambres</h2>
          <form className="space-y-8 flex-grow mb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Bloc Type de chambres */}
              <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                <h3 className="text-xl font-semibold text-gray-700 mb-4">Type de Chambres</h3>
                <div className="flex flex-col space-y-4">
                  <div>
                    <label className="block text-gray-600 font-medium mb-2">Nombre de places</label>
                    <input
                      type="number"
                      placeholder="Nombre de lits"
                      value={roomType.nb_beds}
                      onChange={(e) => handleRoomTypeChange("nb_beds", e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#365644]"
                    />
                  </div>

                  <div className="flex space-x-6">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="rainshower"
                        checked={roomType.isRainshower}
                        onChange={(e) => handleRoomTypeChange("isRainshower", e.target.checked)}
                        className="form-checkbox h-5 w-5 text-green-500 border-gray-300 rounded focus:ring-[#365644]"
                      />
                      <label htmlFor="rainshower" className="ml-3 text-gray-700">Douche pluie</label>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="pmr"
                        checked={roomType.isPMR}
                        onChange={(e) => handleRoomTypeChange("isPMR", e.target.checked)}
                        className="form-checkbox h-5 w-5 text-green-500 border-gray-300 rounded focus:ring-[#365644]"
                      />
                      <label htmlFor="pmr" className="ml-3 text-gray-700">Accessibilité PMR</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Bloc Type de modules */}
              <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                <h3 className="text-xl font-semibold text-gray-700 mb-4">Type de Modules</h3>
                <div className="flex flex-col space-y-4">
                  <div>
                    <label className="block text-gray-600 font-medium mb-2">Version du module</label>
                    <input
                      type="text"
                      placeholder="Version"
                      value={moduleType.version}
                      onChange={(e) => handleModuleTypeChange("version", e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#365644]"
                    />
                  </div>

                  <div>
                    <label className="block text-gray-600 font-medium mb-2">Mode du module</label>
                    <select
                      value={moduleType.mode}
                      onChange={(e) => handleModuleTypeChange("mode", e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#365644]"
                    >
                      <option value={0}>Témoin</option>
                      <option value={1}>Essentiel</option>
                      <option value={2}>Expérience</option>
                    </select>
                  </div>

                  {moduleType.mode == 1 && (
                    <div>
                      <label className="block text-gray-600 font-medium mb-2">Objectif de base</label>
                      <input
                        type="text"
                        placeholder="Objectif de base"
                        value={moduleType.obj_base}
                        onChange={(e) => handleModuleTypeChange("obj_base", e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#365644]"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Chambres par étage */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {roomsPerFloor.map((num_rooms, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg border border-gray-200">
                  <h6 className="text-lg font-medium text-gray-700 mb-4">Étage {index + 1}</h6>
                  <div>
                    <label className="block text-gray-600 font-medium mb-2">Nombre de chambres à l'étage {index + 1}</label>
                    <input
                      type="number"
                      value={num_rooms}
                      onChange={(e) => handleRoomsPerFloorChange(index, e.target.value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#365644]"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-[#365644] text-white px-6 py-2 rounded-lg shadow-md hover:bg-[#2b4737] focus:outline-none focus:ring-2 focus:ring-[#365644]"
              >
                Soumettre
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddFloor;
