import { useNavigate, useLocation } from "react-router-dom";
import luniwaveLogo from "../assets/logo_rond.png";

export function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname.toLowerCase() === path.toLowerCase();

  return (
    <div className="h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 bg-[#98B77F] flex flex-col">
      <div className="flex-grow">
        <div className="mb-2 p-4 flex items-center">
          <button onClick={() => navigate("/")} className="cursor-pointer hover:w-10 h-10 hover:animate-spin">
            <img src={luniwaveLogo} alt="Luniwave Logo" className="w-10 h-10" />
          </button>
          <h5 className="text-xl font-bold text-[#365644] ml-4 cursor-pointer" onClick={() => navigate("/")}>LuniManager</h5>
        </div>
        <ul>
          <li onClick={() => navigate("/")} className={`cursor-pointer p-4 mb-2 rounded ${isActive("/") ? "bg-[#BEDDAB] text-black" : "bg-[#87A774] text-white"}`}>
            Liste des hôtels
          </li>
          <li onClick={() => navigate("/addHotel")} className={`cursor-pointer p-4 mb-2 rounded ${isActive("/addHotel") ? "bg-[#BEDDAB] text-black" : "bg-[#87A774] text-white"}`}>
            Ajouter un hôtel
          </li>
          <li onClick={() => navigate("/alertes")} className={`cursor-pointer p-4 mb-2 rounded ${isActive("/alertes") ? "bg-[#BEDDAB] text-black" : "bg-[#87A774] text-white"}`}>
            Alertes des chambres
          </li>
          <li onClick={() => navigate("/meshs")} className={`cursor-pointer p-4 mb-2 rounded ${isActive("/meshs") ? "bg-[#BEDDAB] text-black" : "bg-[#87A774] text-white"}`}>
            Liste des meshs
          </li>
        </ul>
      </div>
      <div className="p-4">
        <p className="text-sm text-gray-700 text-right">v.0.3.0</p>
      </div>
    </div>
  );
}
