import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useAuth } from "react-oidc-context";

function AddRoom() {
  const location = useLocation();
  const navigate = useNavigate();

  const { num_floor, roomsPerFloor, roomType, hId, listFloorsIndex } = location.state;

  // const auth = useAuth();
  // console.log("auth : ", auth);
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  const initialRooms = Array.from({ length: num_floor }, (_, floorIndex) => {
    return Array.from({ length: roomsPerFloor[floorIndex] }, () => ({
      roomNumber: "",
      mesh: "",
      unicast: "",
    }));
  });

  const [roomDetails, setRoomDetails] = useState(initialRooms);

  const handleRoomChange = (floorIndex, roomIndex, field, value) => {
    const updatedRoomDetails = [...roomDetails];
    updatedRoomDetails[floorIndex][roomIndex][field] = value;
    setRoomDetails(updatedRoomDetails);
  };

  const handleSubmit = () => {
    console.log(roomDetails);

    roomDetails.forEach((floor, index) => {
      const nbfloor = Number(index) + Number(1);
      floor.forEach(async (room) => {
        const roomNumber = room.roomNumber !== "" ? room.roomNumber : null;
        const mesh = room.mesh !== "" ? room.mesh : null;
        const unicast = room.unicast !== "" ? room.unicast : null;

        try {
          const response = await axios.post(
            `${API_URL}/hotels/${hId}/floors/${nbfloor}/rooms`,
            {
              floor_number: nbfloor,
              room_number: roomNumber,
              mesh_number: mesh,
              unicast: unicast,
              num_beds: roomType.nb_beds,
              is_lunishower: true,
              is_rainshower: roomType.isRainshower,
              is_pmr: roomType.isPMR,
              is_alert: false,
              lunione_battery: 25,
              hotel_id: hId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("Réponse du serveur :", response.data);
          navigate("/");
        } catch (error) {
          console.error("Erreur lors de l'envoi des données :", error);
          alert("Erreur lors de l'envoi des données :", error);
        }
      });
    });
    // Submit the room details
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        <div className="flex flex-col items-center justify-start h-full pt-16">
          {" "}
          {/* Updated padding */}
          <div className="max-w-screen-lg w-full">
            <h4 className="text-2xl font-semibold text-black mb-6 text-center">Ajouter des Chambres</h4>
            <div>
              {roomDetails.map((floor, floorIndex) => (
                <div key={floorIndex} className="mb-6">
                  <h6 className="text-xl font-semibold text-blue-gray-900 mb-4">Étage {floorIndex + 1}</h6>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {floor.map((room, roomIndex) => (
                      <div key={roomIndex} className="border p-4 rounded-md bg-gray-100">
                        <div className="mb-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">Numéro de la chambre</label>
                          <input type="text" value={room.roomNumber} onChange={(e) => handleRoomChange(floorIndex, roomIndex, "roomNumber", e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">Mesh associé</label>
                          <input type="text" value={room.mesh} onChange={(e) => handleRoomChange(floorIndex, roomIndex, "mesh", e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">Unicast associé</label>
                          <input type="text" value={room.unicast} onChange={(e) => handleRoomChange(floorIndex, roomIndex, "unicast", e.target.value)} className="w-full p-2 border border-gray-300 rounded-md" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <button className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 mt-6" onClick={handleSubmit}>
                Soumettre
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRoom;
