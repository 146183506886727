import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import luniwaveLogo from "../assets/logo_rond.png"; // Assurez-vous que le chemin est correct
// import { useAuth } from "react-oidc-context";

function RoomDetails() {
  const { roomId } = useParams();
  const [roomDetails, setRoomDetails] = useState(null);

  // const auth = useAuth();
  // console.log("auth : ", auth);
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/alerts/rooms/${roomId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoomDetails(response.data.result[0]);
      } catch (error) {
        console.log("Erreur lors de la récupération du détail d'une chambre.", error);
      }
    };
    fetchRoomDetails();
  }, [roomId]);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        {!roomDetails ? (
          <div className="flex h-full items-center justify-center">
            <div className="text-center">
              <img src={luniwaveLogo} alt="Luniwave Logo" className="w-20 h-20 animate-spin mx-auto" />
              <p className="mt-4 text-xl">Chargement en cours...</p>
            </div>
          </div>
        ) : (
          <div className="bg-white shadow-md rounded-lg overflow-hidden w-full p-3 flex">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Numéro d'étage</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.floor_number}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Numéro de chambre</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.room_number}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Numéro du mesh</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.mesh_number}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Unicast associé</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.unicast}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Nombre de lits</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.num_beds}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Lunishower</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.is_lunishower ? "Oui" : "Non"}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">PMR</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.is_pmr ? "Oui" : "Non"}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Alerte</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.is_alert ? "Oui" : "Non"}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Batterie du lunione</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.lunione_battery}</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">Hôtel associé</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roomDetails.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoomDetails;
