import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
// import luniwaveLogo from "../../assets/logo_rond.png";
// import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";

function AlertDetails() {
  // const { hotel_id, room_id } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [updating, setUpdating] = useState(false);

  // const auth = useAuth();
  const token = null; // Placeholder for token
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchAlertDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/alerts/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAlert(response.data.result);
      } catch (error) {
        console.log("Erreur lors de la récupération des détails de l'alerte.", error);
      }
    };
    fetchAlertDetails();
  }, [id, token]);

  const handleToggleAlert = async () => {
    if (!alert) return;

    setUpdating(true);
    try {
      const url = `${API_URL}/alerts/${id}/${alert.is_closed ? "open" : "close"}`;
      await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAlert((prev) => ({ ...prev, is_closed: !prev.is_closed }));
    } catch (error) {
      console.log(`Erreur lors de la mise à jour de l'alerte.`, error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-start mb-6">
          <button className="text-gray-600 hover:text-gray-900 bg-white rounded-full shadow-md p-2 transition transform hover:scale-105 focus:outline-none" onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className="text-2xl" />
          </button>
        </div>
        <div className="flex justify-center items-center">
          <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Détails de l'Alerte</h2>
            {alert && (
              <div className="space-y-4 text-gray-700">
                <div className="flex justify-between items-center">
                  <span className="font-medium">Type d'Alerte:</span>
                  <span>{alert.alert_type}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">Jours depuis déclenchement:</span>
                  <span>{alert.days}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">Timestamp:</span>
                  <span>{new Date(alert.timestamp).toLocaleString()}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">Contenu:</span>
                  <span>{alert.content}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">Statut:</span>
                  <span className={`font-semibold ${alert.is_closed ? "text-green-500" : "text-red-500"}`}>{alert.is_closed ? "Fermée" : "Ouverte"}</span>
                </div>

                <button onClick={handleToggleAlert} className={`mt-6 w-full py-3 rounded-lg font-semibold text-white transition ${alert.is_closed ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"}`} disabled={updating}>
                  {updating ? "Mise à jour..." : alert.is_closed ? "Réouvrir l'Alerte" : "Fermer l'Alerte"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertDetails;
