import React from "react";
import { useNavigate } from "react-router-dom";

export function Room({ data }) {
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => {
        navigate(`/hotels/${data.hotel_id}/floors/${data.floor_id}/rooms/${data.id}`, {
          state: { hotel_id: data.hotel_id, floor_id: data.floor_id, id: data.id },
        });
      }}
      className="cursor-pointer hover:bg-gray-50"
    >
      <td className="px-6 py-4 whitespace-nowrap">{data.room_nb}</td>
      <td className="px-6 py-4 whitespace-nowrap">{data.nb_places}</td>
    </tr>
  );
}
