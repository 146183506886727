import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import luniwaveLogo from "../../assets/logo_rond.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { useAuth } from "react-oidc-context";

function RoomAlerts() {
  const { hotel_id, room_id } = useParams();
  const [allRoomAlerts, setAllRoomAlerts] = useState(null);
  const [room_nb, setRoomNb] = useState();
  const [activeFilter, setActiveFilter] = useState("open"); // Open alerts by default
  const navigate = useNavigate();

  // const auth = useAuth();
  const token = null; // Placeholder for token
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/hotels/${hotel_id}/rooms/${room_id}/alerts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllRoomAlerts(response.data.result);
        setRoomNb(response.data.result[0].room_nb);
      } catch (error) {
        console.log("Erreur lors de la récupération des alertes de la chambre.", error);
      }
    };
    fetchRoomDetails();
  }, [hotel_id, room_id]);

  const renderAlertType = (alertType) => {
    switch (alertType) {
      case "1":
        return "Batterie Faible";
      case "2":
        return "Pas de Heartbeat";
      case "3":
        return "Pas d'intéractions";
      case "4":
        return "Pas de douches";
      case "5":
        return "Moyenne trop basse";
      case "6":
        return "Moyenne trop haute";
      default:
        return "Inconnu";
    }
  };

  const renderStatus = (isClosed) => {
    return isClosed ? <span className="inline-block w-4 h-4 bg-green-500 rounded-full"></span> : <span className="inline-block w-4 h-4 bg-red-500 rounded-full"></span>;
  };

  const handleAlertClick = (alertId) => {
    navigate(`/hotels/${hotel_id}/rooms/${room_id}/alerts/${alertId}`);
  };

  const filteredAlerts = allRoomAlerts ? allRoomAlerts.filter((alert) => (activeFilter === "open" ? !alert.is_closed : alert.is_closed)) : [];

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        <div className="flex items-start mb-6">
          <button className="text-gray-600 hover:text-gray-900 bg-white rounded-full shadow-md p-2 transition transform hover:scale-105 focus:outline-none" onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className="text-2xl" />
          </button>
        </div>
        <div className="flex justify-center mb-4">
          <button className={`p-3 rounded cursor-pointer ${activeFilter === "open" ? "bg-red-200" : "bg-gray-50"}`} onClick={() => setActiveFilter("open")}>
            Alertes Ouvertes
          </button>
          <button className={`p-3 rounded cursor-pointer ml-4 ${activeFilter === "closed" ? "bg-green-200" : "bg-gray-50"}`} onClick={() => setActiveFilter("closed")}>
            Alertes Fermées
          </button>
        </div>
        <hr className="my-6 border-t-2 border-gray-300 w-full" />
        {!allRoomAlerts ? (
          <div className="flex h-full items-center justify-center">
            <div className="text-center">
              <img src={luniwaveLogo} alt="Luniwave Logo" className="w-20 h-20 animate-spin mx-auto" />
              <p className="mt-4 text-xl">Chargement en cours...</p>
            </div>
          </div>
        ) : (
          <div className="bg-white shadow-md rounded-lg overflow-hidden w-full p-3">
            <h2 className="text-2xl mb-4 text-center">
              {activeFilter === "open" ? "Alertes Ouvertes" : "Alertes Fermées"} pour la chambre {room_nb}
            </h2>
            {filteredAlerts.length === 0 ? (
              <div className="p-6 text-center text-gray-500">Aucune alerte {activeFilter === "open" ? "ouverte" : "fermée"}.</div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Type d'alerte</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Jours</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Timestamp</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Contenu</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Statut</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredAlerts.map((alert) => (
                    <tr key={alert.id} className="text-center cursor-pointer hover:bg-gray-100" onClick={() => handleAlertClick(alert.id)}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{renderAlertType(alert.alert_type)}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{alert.days}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(alert.timestamp).toLocaleString()}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{alert.content}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{renderStatus(alert.is_closed)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default RoomAlerts;
