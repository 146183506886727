import { Sidebar } from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";

function GestionMeshForHotel() {
  const navigate = useNavigate();
  const { id: hotel_id } = useParams();
  // const auth = useAuth();
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  const [hotelMeshs, setHotelMeshs] = useState([]);
  const [meshsNoHotel, setMeshsNoHotel] = useState([]);
  const [selectedMeshs, setSelectedMeshs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newMesh, setNewMesh] = useState({
    wakeup_policy: "",
    ip_adress_hub: "",
    netkey: "",
    appKey: "",
    status: 0,
    content: "",
  });

  useEffect(() => {
    axios
      .get(`${API_URL}/hotels/${hotel_id}/mesh`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setHotelMeshs(response.data.result))
      .catch((error) => console.error("Error fetching meshs for hotel:", error));

    axios
      .get(`${API_URL}/meshsNoHotel`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => setMeshsNoHotel(response.data.result))
      .catch((error) => console.error("Error fetching meshs with no hotel:", error));
  }, [hotel_id, token, API_URL]);

  const handleMeshClick = (mesh) => {
    setSelectedMeshs((prev) => (prev.includes(mesh) ? prev.filter((m) => m.id !== mesh.id) : [...prev, mesh]));
  };

  const handleSend = () => {
    selectedMeshs.forEach((mesh) => {
      axios
        .patch(`${API_URL}/hotels/${hotel_id}/mesh/${mesh.id}/associate`, null, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => window.location.reload())
        .catch((error) => console.error("Error associating meshs:", error));
    });
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleChange = (e) => setNewMesh({ ...newMesh, [e.target.name]: e.target.value });

  const handleCreateMesh = () => {
    axios
      .post(`${API_URL}/hotels/${hotel_id}/meshs`, { ...newMesh, hotel_id }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        toggleModal();
        setHotelMeshs((prev) => [...prev, response.data.result]);
      })
      .catch((error) => console.error("Error creating mesh:", error));
  };

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>
          <h1 className="text-2xl font-bold">Gestion Meshs</h1>
        </div>

        <hr className="my-6 border-t-2 border-gray-300 w-full mb-8" />

        <button onClick={toggleModal} className="w-full max-w-sm bg-[#98B77F] text-white font-semibold py-3 rounded-lg hover:bg-[#365644] transition duration-300">
          Créer un nouveau mesh
        </button>

        {isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
          <button 
            type="button" 
            className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" 
            onClick={toggleModal} 
            aria-label="Close"
          >
            &times;
          </button>
          <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Créer un nouveau Mesh</h2>
          <form className="space-y-4">
            <div className="flex flex-col">
              <label className="mb-1 font-medium">Wakeup Policy</label>
              <input 
                type="text" 
                name="wakeup_policy" 
                value={newMesh.wakeup_policy} 
                onChange={handleChange} 
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-medium">IP Address Hub</label>
              <input 
                type="text" 
                name="ip_adress_hub" 
                value={newMesh.ip_adress_hub} 
                onChange={handleChange} 
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-medium">Netkey</label>
              <input 
                type="text" 
                name="netkey" 
                value={newMesh.netkey} 
                onChange={handleChange} 
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-medium">AppKey</label>
              <input 
                type="text" 
                name="appKey" 
                value={newMesh.appKey} 
                onChange={handleChange} 
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-medium">Status</label>
              <select 
                name="status" 
                value={newMesh.status} 
                onChange={handleChange} 
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black"
              >
                <option value="0">Inactive</option>
                <option value="1">Active</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-medium">Content</label>
              <input 
                type="text" 
                name="content" 
                value={newMesh.content} 
                onChange={handleChange} 
                className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" 
              />
            </div>
            <button 
              type="button" 
              onClick={handleCreateMesh} 
              className="w-full bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200"
            >
              Créer un nouveau mesh
            </button>
          </form>
        </div>
      </div>
    )}

        <h2 className="text-2xl font-semibold text-gray-800 mt-10 mb-6">Meshs associés à l'hôtel</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {hotelMeshs.map((mesh) => (
            <div key={mesh.id} className="border p-6 rounded-lg shadow-md bg-gray-50">
              <h3 className="font-semibold text-lg">Mesh ID: {mesh.id}</h3>
              <p className="text-gray-700">IP Address Hub: {mesh.ip_adress_hub}</p>
              <p className="text-gray-700">Status: {mesh.status ? "Active" : "Inactive"}</p>
              <p className="text-gray-700">Wakeup Policy: {mesh.wakeup_policy || "Not set"}</p>
              <p className="text-gray-700">Créer le : {new Date(mesh.created_at).toLocaleString()}</p>
              <p className="text-gray-700">Mis à jour le : {new Date(mesh.updated_at).toLocaleString()}</p>
            </div>
          ))}
        </div>

        <hr className="my-6 border-t-2 border-gray-300 w-full mb-8" />

        <h2 className="text-2xl font-semibold text-gray-800 mt-10 mb-6">Meshs non-associés</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {meshsNoHotel.map((mesh) => (
            <div key={mesh.id} onClick={() => handleMeshClick(mesh)} className={`border p-6 rounded-lg shadow-md cursor-pointer transition ${selectedMeshs.includes(mesh) ? "bg-green-500 text-white" : "bg-gray-50"}`}>
              <h3 className="font-semibold text-lg">Mesh ID: {mesh.id}</h3>
              <p className={`${selectedMeshs.includes(mesh) ? "text-white" : "text-gray-700"}`}>IP Address Hub: {mesh.ip_adress_hub}</p>
              <p className={`${selectedMeshs.includes(mesh) ? "text-white" : "text-gray-700"}`}>Status: {mesh.status ? "Active" : "Inactive"}</p>
              <p className={`${selectedMeshs.includes(mesh) ? "text-white" : "text-gray-700"}`}>Wakeup Policy: {mesh.wakeup_policy || "Not set"}</p>
              <p className={`${selectedMeshs.includes(mesh) ? "text-white" : "text-gray-700"}`}>Created At: {new Date(mesh.created_at).toLocaleString()}</p>
              <p className={`${selectedMeshs.includes(mesh) ? "text-white" : "text-gray-700"}`}>Updated At: {new Date(mesh.updated_at).toLocaleString()}</p>
            </div>
          ))}
        </div>

        <div className="mt-10">
          <button onClick={handleSend} className="w-full max-w-sm bg-[#98B77F] text-white font-semibold py-3 rounded-lg hover:bg-[#365644] transition duration-300">
            Associer les meshs sélectionnés
          </button>
        </div>
      </div>
    </div>
  );
}

export default GestionMeshForHotel;
