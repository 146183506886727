import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
// import { useAuth } from "react-oidc-context";

function Mesh() {
  const [listeMeshs, setListeMeshs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [wakeup_policy, setWakeupPolicy] = useState("");
  const [ip_adress_hub, setIpAddressHub] = useState("");
  const [netkey, setNetKey] = useState("");
  const [appKey, setAppKey] = useState("");
  const [status, setStatus] = useState("");
  const [content, setContent] = useState("");

  // const auth = useAuth();
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchMeshsData = async () => {
      try {
        const responseMeshs = await axios.get(`${API_URL}/meshs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setListeMeshs(responseMeshs.data.result);
      } catch (error) {
        console.error("error fetching mesh data:", error);
      }
    };

    fetchMeshsData();
  }, [API_URL, token]);

  const handleSubmit = async () => {
    if (!wakeup_policy || !ip_adress_hub || !netkey || !appKey || !status || !content) {
      alert("Tous les champs doivent être remplis.");
      return;
    }

    if (!["0", "1", "2", "3", "4", "5", "6", "7"].includes(wakeup_policy)) {
      alert("Politique de réveil non valide.");
      return;
    }

    try {
      await axios.post(
        `${API_URL}/meshs`,
        {
          wakeup_policy,
          ip_adress_hub,
          netkey,
          appKey,
          status,
          content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Mesh ajouté avec succès.");

      const responseMeshs = await axios.get(`${API_URL}/meshs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setListeMeshs(responseMeshs.data.result);
      setIsModalOpen(false);
    } catch (err) {
      console.error("Error adding mesh:", err);
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 p-4 overflow-auto">
        <div className="flex justify-between item-center mb-4 mt-8">
          <h1 className="ml-6 font-semibold text-2xl mb-4">Liste des meshs</h1>
          <button className="bg-[#365644] text-white px-4 py-2 rounded mb-3 mr-4" onClick={toggleModal} style={{ marginLeft: "auto" }}>
            Ajouter un mesh non-associé
          </button>
        </div>

        <hr className="my-6 border-t-2 border-gray-300 w-full" />

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Politique de réveil</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Adresse IP du hub</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">NetKey</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">AppKey</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Etat</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Contenu</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {listeMeshs && listeMeshs.length > 0 ? (
                listeMeshs.map((mesh) => (
                  <tr key={mesh.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{mesh.wakeup_policy}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{mesh.ip_adress_hub}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{mesh.netKey}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{mesh.appKey}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{mesh.status}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{mesh.content}</td>
                  </tr>
                ))
              ) : (
                <div className="p-6 text-center text-gray-500">Aucun mesh disponible pour le moment.</div>
              )}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full space-y-4">
              <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Ajout d'un mesh non-associé</h2>
              <button type="button" className="absolute top-4 right-8 text-gray-600 hover:text-gray-900 text-2xl" onClick={toggleModal} aria-label="Close">
                &times;
              </button>

              <div className="flex flex-wrap p-2 mb-4">
                <div className="sm:w-1/2 px-4 space-y-4 w-full">
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">Politique de réveil</label>
                    <input type="text" name="wakeup_policy" value={wakeup_policy} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" onChange={(e) => setWakeupPolicy(e.target.value)} />
                  </div>

                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">Adresse IP du hub</label>
                    <input type="text" name="ip_adress_hub" value={ip_adress_hub} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" onChange={(e) => setIpAddressHub(e.target.value)} />
                  </div>

                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">NetKey</label>
                    <input type="text" name="Netkey" value={netkey} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" onChange={(e) => setNetKey(e.target.value)} />
                  </div>
                </div>

                <div className="sm:w-1/2 px-4 space-y-4 w-full">
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">AppKey</label>
                    <input type="text" name="AppKey" value={appKey} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" onChange={(e) => setAppKey(e.target.value)} />
                  </div>

                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">Etat</label>
                    <input type="number" name="Etat" value={status} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" onChange={(e) => setStatus(e.target.value)} />
                  </div>            

                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">Contenu</label>
                    <input type="text" name="content" value={content} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644] text-black" onChange={(e) => setContent(e.target.value)} />
                  </div>
                </div>
              </div>

              <button onClick={handleSubmit} className="w-full bg-[#365644] text-white px-4 py-2 rounded-lg hover:bg-[#2d4737] transition duration-200">
                Enregistrer les modifications
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Mesh;
