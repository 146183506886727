import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import luniwaveLogo from "../assets/logo_rond.png";
import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Carrousel } from "../components/Room/Carroussel_Room";
import { Shower } from "../components/Room/Shower";

function RoomPage() {
  const { hotel_id, floor_id, id } = useParams();
  const navigate = useNavigate();

  const [listShowers, setListShowers] = useState();
  const [chambre, setChambre] = useState();
  const [hotel, setHotel] = useState();

  const auth = useAuth();
  // console.log("auth : ", auth);
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const responseChambres = await axios.get(`${API_URL}/hotels/${hotel_id}/floors/${floor_id}/rooms/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setChambre(responseChambres.data.result);

        const responseShowers = await axios.get(`${API_URL}/hotels/${hotel_id}/floors/${floor_id}/rooms/${id}/showers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setListShowers(responseShowers.data);
      } catch (error) {
        console.error("Error fetching shower data:", error);
      }
    };

    axios
      .get(`${API_URL}/hotels/${hotel_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const resultHotel = response.data.result;
        setHotel(resultHotel);
      });

    fetchRoomData();
  }, [hotel_id, floor_id, id]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(`/hotels/${hotel_id}/floors/${floor_id}`)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>

          {chambre && <h1 className="text-2xl font-bold">Détail de la chambre : {chambre.room_nb} </h1>}
        </div>

        <div className="mt-5 flex flex-col">
          <p className="text-lg font-semibold text-black mb-2">Informations concernant l'hôtel</p>
          <span className="bg-gray-200 rounded-lg text-black italic p-4 text-m mb-3">{hotel?.content}</span>
        </div>

        {hotel && chambre && <Carrousel hotel={hotel} chambre={chambre} className="mt-4" />}

        {!chambre ? (
          <div className="flex h-full items-center justify-center">
            <div className="text-center">
              <img src={luniwaveLogo} alt="Luniwave Logo" className="w-20 h-20 animate-spin mx-auto" />
              <p className="mt-4 text-xl">Chargement en cours...</p>
            </div>
          </div>
        ) : (
          <div className="mb-4 mt-4">
            <h2 className="font-bold text-lg mb-2">Liste des douches</h2>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Date de la douche</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Température (°C)</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Volume (par L)</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Objectif fixé (par L)</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Intéraction effectuée</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 text-center">
                  {listShowers && listShowers.result.length === 0 ? (
                    <div>Aucune douche disponible pour le moment.</div>
                  ) : (
                    listShowers &&
                    listShowers.result.map((shower, index) => {
                      return <Shower key={index} data={shower} />;
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RoomPage;
