import { useEffect, useState } from "react";
import { Sidebar } from "../components/Sidebar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Room } from "../components/Room/Room";
import { useAuth } from "react-oidc-context";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Carrousel } from "../components/Floor/Carroussel_Floor";

function FloorPage() {
  const { hotel_id, floor_id } = useParams();
  const navigate = useNavigate();

  const [listeChambres, setListeChambres] = useState();
  const [etage, setEtage] = useState();
  const [hotel, setHotel] = useState();

  const auth = useAuth();
  // console.log("auth : ", auth);
  // const token = auth.user.access_token;
  const token = null;
  // const API_URL = process.env.REACT_APP_API_URL;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    const fetchFloorData = async () => {
      try {
        const responseEtage = await axios.get(`${API_URL}/hotels/${hotel_id}/floors/${floor_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEtage(responseEtage.data.result);

        const responseChambres = await axios.get(`${API_URL}/hotels/${hotel_id}/floors/${floor_id}/rooms`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setListeChambres(responseChambres.data);
      } catch (error) {
        console.error("Error fetching floor data:", error);
      }
    };

    axios
      .get(`${API_URL}/hotels/${hotel_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const resultHotel = response.data.result;
        setHotel(resultHotel);
      });

    fetchFloorData();
  }, [hotel_id, floor_id]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 overflow-auto">
        <div className="flex items-center">
          <button className="mr-3 text-black px-4 py-2 rounded flex items-center" onClick={() => navigate(`/hotels/${hotel_id}`)}>
            <AiOutlineArrowLeft className="text-lg text-bold text-xl" />
          </button>

          {etage && <h1 className="text-2xl font-bold">Détail de l'étage : {etage.floor_number} </h1>}
        </div>

        <div className="mt-5 flex flex-col">
          <p className="text-lg font-semibold text-black mb-2">Informations concernant l'hôtel</p>
          <span className="bg-gray-200 rounded-lg text-black italic p-4 text-m mb-3">{hotel?.content}</span>
        </div>

        {hotel && etage && <Carrousel hotel={hotel} etage={etage} className="mt-4" />}

        <div className="mb-4 mt-4">
          <h2 className="font-bold text-lg mb-2">Liste des Chambres</h2>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Numéro de Chambre</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">Nombre de Lits</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-center">
                {listeChambres?.result.length === 0 ? (
                  <div>Aucune chambre disponible pour le moment.</div>
                ) : (
                  listeChambres &&
                  listeChambres.result.map((chambre, index) => {
                    return <Room key={index} data={chambre} />;
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloorPage;
