import React from "react";
import moment from 'moment';
import 'moment/locale/fr';

export function Shower({ data }) {
    moment.locale('fr');

    const formatTime =(time) => {
        return moment(time).format('LL');
    };

  return (
    <tr>
        <td className="px-6 py-4 whitespace-nowrap">{formatTime(data.time)}</td>
        <td className="px-6 py-4 whitespace-nowrap">{data.temperature}</td>
        <td className="px-6 py-4 whitespace-nowrap">{data.volume}</td>
        <td className="px-6 py-4 whitespace-nowrap">{data.objective}</td>
        <td className="px-6 py-4 whitespace-nowrap">{data.interaction}</td>
    </tr>
  );
}
