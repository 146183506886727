import React, { useState, useEffect } from "react";
import axios from "axios";
import { Sidebar } from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "react-oidc-context";

function AddHotel() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [heatingType, setHeatingType] = useState("");
  const [starsNumber, setStarsNumber] = useState("");
  const [numRooms, setNumRooms] = useState("");
  const [numFloor, setNumFloor] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [alertsSettingsProfileList, setAlertsSettingsProfileList] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState("");
  const [manager, setManager] = useState("");
  const [selectedProfileDetails, setSelectedProfileDetails] = useState(null);

  const [nameValid, setNameValid] = useState(true);
  const [managerValid, setManagerValid] = useState(true);
  const [locationValid, setLocationValid] = useState(true);
  const [heatingTypeValid, setHeatingTypeValid] = useState(true);
  const [starsNumberValid, setStarsNumberValid] = useState(true);
  const [numRoomsValid, setNumRoomsValid] = useState(true);
  const [numFloorValid, setNumFloorValid] = useState(true);

  // const auth = useAuth();
  const token = null;
  const API_URL = "https://api-manager-staging.luniwave.com";

  useEffect(() => {
    axios
      .get(`${API_URL}/alertsSettings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAlertsSettingsProfileList(response.data.result);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !location || !heatingType || !starsNumber || !numRooms || !numFloor || !manager || !selectedProfileId) {
      alert("Veuillez remplir tous les champs obligatoires.");
      setNameValid(!!name);
      setLocationValid(!!location);
      setHeatingTypeValid(!!heatingType);
      setStarsNumberValid(!!starsNumber);
      setNumRoomsValid(!!numRooms);
      setNumFloorValid(!!numFloor);
      return;
    }

    const newHotel = {
      name,
      location,
      la_manager: manager,
      subscription_type: parseInt(subscriptionType),
      heating_type: heatingType,
      stars_number: parseInt(starsNumber),
      num_rooms: parseInt(numRooms),
      num_floor: parseInt(numFloor),
      alert_profile_id: parseInt(selectedProfileId),
      content: "",
      is_visible: 1,
    };

    try {
      navigate("/addFloor", {
        state: {
          newHotel: newHotel,
          num_rooms: numRooms,
          num_floor: numFloor,
        },
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'hôtel :", error);
    }
  };

  const handleProfileChange = (e) => {
    const profileId = e.target.value;
    setSelectedProfileId(profileId);
    const selectedProfile = alertsSettingsProfileList.find((profile) => profile.id === parseInt(profileId));
    setSelectedProfileDetails(selectedProfile || null);
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 p-8 overflow-hidden">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto h-full overflow-y-auto">
          <h2 className="text-xl font-bold text-center mb-6 text-gray-800">Ajouter un Hôtel</h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Nom de l'hôtel</label>
                <input
                  type="text"
                  placeholder="Nom de l'hôtel"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValid(!!e.target.value);
                  }}
                  className={`border ${nameValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Adresse de l'hôtel</label>
                <input
                  type="text"
                  placeholder="38 Rue de l'Eau"
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setLocationValid(!!e.target.value);
                  }}
                  className={`border ${locationValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Nombre d'étoiles</label>
                <input
                  type="number"
                  placeholder="Nombre d'étoiles"
                  value={starsNumber}
                  onChange={(e) => {
                    setStarsNumber(e.target.value);
                    setStarsNumberValid(!!e.target.value);
                  }}
                  className={`border ${starsNumberValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Type de chauffage</label>
                <input
                  type="text"
                  placeholder="Type de chauffage"
                  value={heatingType}
                  onChange={(e) => {
                    setHeatingType(e.target.value);
                    setHeatingTypeValid(!!e.target.value);
                  }}
                  className={`border ${heatingTypeValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Manager (Commercial)</label>
                <input
                  type="text"
                  placeholder="Nom du manager"
                  value={manager}
                  onChange={(e) => {
                    setManager(e.target.value);
                    setManagerValid(!!e.target.value);
                  }}
                  className={`border ${managerValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Nombre d'étages</label>
                <input
                  type="number"
                  placeholder="Nombre d'étages"
                  value={numFloor}
                  onChange={(e) => {
                    setNumFloor(e.target.value);
                    setNumFloorValid(!!e.target.value);
                  }}
                  className={`border ${numFloorValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Nombre de chambres</label>
                <input
                  type="number"
                  placeholder="Nombre de chambres"
                  value={numRooms}
                  onChange={(e) => {
                    setNumRooms(e.target.value);
                    setNumRoomsValid(!!e.target.value);
                  }}
                  className={`border ${numRoomsValid ? "border-gray-300" : "border-red-500"} rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]`}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium">Type d'abonnement</label>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="subscription_type"
                    value="0"
                    checked={subscriptionType === "0"}
                    onChange={(e) => setSubscriptionType(e.target.value)}
                    className="mr-2"
                  />
                  <label className="mr-4">Standard</label>
                  <input
                    type="radio"
                    name="subscription_type"
                    value="1"
                    checked={subscriptionType === "1"}
                    onChange={(e) => setSubscriptionType(e.target.value)}
                    className="mr-2"
                  />
                  <label>Premium</label>
                </div>
              </div>
              </div>

              <hr className="my-6 border-t-2 border-gray-300 w-full" />

              <div className="flex flex-col mb-4">
                <label className="mb-1 w-50 font-medium">Profil des alertes</label>
                <select value={selectedProfileId} onChange={handleProfileChange} className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#365644]">
                  <option value="">Sélectionnez un profil d'alertes</option>
                  {alertsSettingsProfileList.map((profile) => (
                    <option key={profile.id} value={profile.id}>
                      Profil {profile.id}
                    </option>
                  ))}
                </select>
              </div>

            {selectedProfileDetails && (
              <div className="mt-4 p-4 bg-gray-50 border rounded-lg text-left">
                <h3 className="font-bold text-gray-700 mb-2">Détails du Profil {selectedProfileDetails.id}</h3>
                <p className="text-gray-600">Batterie minimum: {selectedProfileDetails.minimum_battery}%</p>
                <p className="text-gray-600">Pas de heartbeat pendant: {selectedProfileDetails.no_heartbeat_period} min</p>
                <p className="text-gray-600">Max Door Opened: {selectedProfileDetails.max_door_opened} fois</p>
              </div>
            )}

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="bg-[#365644] text-white px-6 py-2 rounded-lg shadow-md hover:bg-[#2b4737] focus:outline-none focus:ring-2 focus:ring-[#365644]"
              >
                Etape suivante
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddHotel;